import { graphql } from "gatsby"
import "twin.macro"
import * as React from "react"
import HeroBanner from "@molecules/hero-banner"
import TextContent from "@molecules/text-content"
import Seo from "@atoms/seo"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ColumnsSection from "../components/organisms/columns_section"

const AboutPage = ({
  data: {
    ft: { about },
  },
}) => (
  <>
    {about.seo && <Seo {...about.seo} />}
    {about.banner && <HeroBanner data={about.banner} />}
    <TextContent headingXl={true} data={about.text}>
      <div tw="w-full pt-8 pb-6 px-6 flex items-center justify-center sm:justify-end">
        {about.linked_image.map(({ id, image, url, name }) => (
          <a
            key={id}
            href={url}
            target="_BLANK"
            rel="noreferrer"
            title={name}
            tw="block"
          >
            <GatsbyImage
              key={image.id}
              image={getImage(image.localFile)}
              alt={name}
            />
          </a>
        ))}
      </div>
    </TextContent>
    <ColumnsSection data={about.profiles} />
  </>
)

export const query = graphql`
  query AboutPage($locale: String) {
    ft {
      about(locale: $locale) {
        name
        slug
        seo {
          ...Seo
        }
        profiles {
          ...ColumnsSection
        }
        banner {
          ...HeroBanner
        }
        text {
          ...TextContent
        }
        linked_image {
          id
          url
          name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 180
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: TRACED_SVG
                )
              }
            }
            url
            id
          }
        }
      }
    }
  }
`
export default AboutPage
